<template>
  <v-container
    id="dataexport"
    fluid
    tag="section"
    class="grey lighten-4"
  >
    <!-- Dashboard Block Info' toolbar -->
    <v-toolbar
      style="z-index: 1"
      color="elevation-0"
      dense
    >
      <v-toolbar-title class="text-h2 grey--text text--darken-0 pr-8">
        {{ $store.state.main.af.title }} - {{ $t('section-title.form-settings') }}
      </v-toolbar-title>
      <v-spacer />

      <!-- Include Datasets for download, open and close card -->
      <v-btn
        v-if="myRole() === 'manager'"
        :color="colorBtn"
        fab
        dark
        @click="helptoolbar = !helptoolbar"
      >
        <v-icon>mdi-information-variant</v-icon>
      </v-btn>
    </v-toolbar>

    <!-- Dashboard Datasets Info -->
    <v-card
      v-if="!helptoolbar"
      class="mt-0"
      color="elevation-0"
    >
      <v-row class="px-4">
        <!-- First column -->
        <v-col
          cols="12"
          md="9"
        >
          <v-card-text class="body-1 grey--text text--darken-2">
            <div> {{ $t('form-settings.instance') }}: {{ af.odksettings.id }} </div>
            <div> {{ $t('form-settings.version') }}: {{ `${af.odksettings.version} (${af.odksettings.public_key.length > 0 ? $t('form-settings.encrypted') : $t('form-settings.not-encrypted')})` }} </div>
            <div> {{ $t('form-settings.description') }}: {{ formDescription }} </div>
          </v-card-text>
        </v-col>
        <!-- Second column -->
        <v-col
          cols="12"
          md="3"
        >
          <v-card-text class="grey--text text--darken-1">
            {{ $t('common.project') }}: {{ $store.state.bend.organization.projects[ix.pindex].title }}
          </v-card-text>
        </v-col>
      </v-row>
      <v-card-actions>
        <v-row class="pb-4 px-8">
          <v-spacer />
          <!-- Keep closed -->
          <v-checkbox
            v-model="dBInfo"
            :label="$t('dashboard.keep-closed')"
            class="py-0 my-0"
            hide-details
          />
        </v-row>
      </v-card-actions>
    </v-card>

    <!-- Settings -->
    <v-row
      class="d-flex justify-space-between mt-8"
    >
      <!-- Data Collectors Card -->
      <v-col
        lg="5"
        md="4"
        sm="12"
      >
        <v-card
          class="mt-2 mr-2"
          outlined
        >
          <v-toolbar
            flat
            :color="colorTlb"
            dark
            dense
          >
            <v-icon left>
              mdi-account-box
            </v-icon>
            <v-toolbar-title>{{ $t('form-settings.data-collectors') }}</v-toolbar-title>
            <v-spacer />

            <!-- Add NEW Weblink button -->
            <v-btn
              v-if="myRole() === 'manager'"
              :color="colorBtn"
              fab
              @click="dialogDCol = true"
            >
              <v-icon>mdi-qrcode-plus</v-icon>
            </v-btn>
          </v-toolbar>
          <!-- Add NEW App User button -->
          <v-card-text>
            <!-- <v-toolbar flat>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                class="ml-auto"
                :label="$t('common.search')"
                hide-details
                single-line
                style="max-width: 250px;"
              />
            </v-toolbar> -->
            <v-data-table
              v-model="selected"
              :loading="appUserLoad"
              loading-text="Loading... Please wait"
              :headers="appuheaders"
              :items="appUsers"
              item-key="displayName[0]"
              :show-select="myRole() === 'manager'"
              :page.sync="dcpage"
              :items-per-page="itemsPerPage"
              hide-default-footer
              @page-count="dcPageCount = $event"
              @item-selected="updateAppUsers"
            >
              <!-- Action buttons -->
              <template v-slot:[`item.actions`]="{ item }">
                <!-- QR Code item -->
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <v-icon
                      class="mr-3"
                      color="indigo"
                      v-on="on"
                      @click="showQRCode(item)"
                    >
                      mdi-qrcode
                    </v-icon>
                  </template>
                  <span>{{ $t('tooltip.qr-see') }}</span>
                </v-tooltip>
              </template>

              <!-- No Collector button -->
              <template v-slot:no-data="">
                <v-btn to="/account/project">
                  {{ $t('form-settings.data-collector-add') }}
                </v-btn>
              </template>
            </v-data-table>
            <div class="text-center pt-2">
              <v-pagination
                v-model="dcpage"
                light
                :length="dcPageCount"
              />
            </div>
          </v-card-text>
          <!-- <v-card-text class="text-right body-1 grey--text pt-0">
            {{ $t('form-settings.data-coll-note') }}
            <router-link :to="{ name: 'Project Settings', params: { indexes: ix }}">
              {{ $t('section-title.project-settings') }}
            </router-link>
          </v-card-text> -->
        </v-card>
      </v-col>

      <!-- Pulic Links Card -->
      <v-col
        lg="7"
        md="8"
        sm="12"
      >
        <v-card
          class="mt-2 mr-2"
          outlined
        >
          <v-toolbar
            flat
            :color="colorTlb"
            dark
            dense
          >
            <v-icon left>
              mdi-web
            </v-icon>
            <v-toolbar-title>{{ $t('form-settings.public-link') }}</v-toolbar-title>
            <v-spacer />

            <!-- Add NEW Weblink button -->
            <v-btn
              v-if="myRole() === 'manager'"
              :color="colorBtn"
              fab
              @click="webLinkDialog"
            >
              <v-icon>mdi-web-plus</v-icon>
            </v-btn>
          </v-toolbar>

          <v-card-text class="grey--text text--darken-1">
            <v-data-table
              :loading="webLinkLoad"
              loading-text="Loading... Please wait"
              :headers="weblinkheaders"
              :items="webLinks"
              item-key="id"
              :page.sync="plpage"
              :items-per-page="itemsPerPage"
              hide-default-footer
              @page-count="plPageCount = $event"
            >
              <!-- Action buttons -->
              <template v-slot:[`item.actions`]="{ item }">
                <!-- QR Code item -->
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <v-btn
                      v-if="item.name !== 'NA' && myRole() === 'manager'"
                      small
                      dark
                      icon
                      @click="openDialogDeleteLink(item.id)"
                    >
                      <v-icon
                        color="primary"
                        v-on="on"
                      >
                        mdi-delete
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('form-settings.del-join-tbl') }}</span>
                </v-tooltip>
              </template>

              <!-- No Collector button -->
              <!-- <template v-slot:no-data="">
                <v-btn to="/account/project">
                  {{ $t('form-settings.data-collector-add') }}
                </v-btn>
              </template> -->
            </v-data-table>
            <div class="text-center pt-2">
              <v-pagination
                v-model="plpage"
                light
                :length="plPageCount"
              />
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Join Tables Card -->
      <v-col
        md="7"
        lg="8"
        sm="12"
      >
        <v-card
          class="mt-2 mr-2"
          outlined
        >
          <v-toolbar
            flat
            :color="colorTlb"
            dark
            dense
          >
            <v-icon left>
              mdi-table-multiple
            </v-icon>
            <v-toolbar-title>{{ $t('form-settings.join-tables') }}</v-toolbar-title>
            <v-spacer />

            <!-- Add NEW Join Table button -->
            <v-btn
              v-if="myRole() === 'manager'"
              :color="colorBtn"
              fab
              @click="dialogJoin = true"
            >
              <v-icon>mdi-table-plus</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text class="grey--text text--darken-1">
            <!-- <div class="my-3">
              {{ $t('mpref.fs-table-list') }}
            </div> -->
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      {{ $t('table-header.name') }}
                    </th>
                    <th class="text-left">
                      {{ $t('table-header.type') }}
                    </th>
                    <th class="text-left">
                      {{ $t('table-header.actions') }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in dataSet"
                    :key="item.label[0]"
                  >
                    <td>{{ item.label[0] }}</td>
                    <td>{{ $t(coretable(item.core[0])) }} </td>
                    <td
                      class="text-right"
                    >
                      <v-tooltip
                        v-if="item.core[0]===false"
                        bottom
                      >
                        <template #activator="{ on }">
                          <!-- normal buttons -->
                          <v-btn
                            v-if="myRole() === 'manager'"
                            small
                            dark
                            icon
                            @click="joinTableDelete(item.table[0])"
                          >
                            <v-icon
                              color="primary"
                              v-on="on"
                            >
                              mdi-delete
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t('form-settings.del-join-tbl') }}</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Synchronization Mode -->
      <v-col
        md="5"
        lg="4"
        sm="12"
      >
        <v-card
          class="mt-2 mr-2"
          outlined
        >
          <v-toolbar
            flat
            :color="colorTlb"
            dark
            dense
          >
            <v-icon left>
              mdi-cloud-sync
            </v-icon>
            <v-toolbar-title>{{ $t('mpref.fs-sync-mode') }}</v-toolbar-title>
            <v-spacer />
          </v-toolbar>

          <!-- SyncMode Part -->
          <v-card-text>
            <v-radio-group
              v-model="sMode"
              :disabled="myRole() !== 'manager'"
              column
              class="ml-4 mt-3"
            >
              <v-radio
                v-for="s in syncMode"
                :key="s.label"
                :color="s.color"
                :label="translateMe(s.label)"
                :value="s.value"
                @change="setSync(s.value)"
              />
            </v-radio-group>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Form Versions Card -->
      <v-col
        md="7"
        lg="8"
        sm="12"
      >
        <v-card
          class="mt-2 mr-2"
          outlined
        >
          <v-toolbar
            flat
            :color="colorTlb"
            dark
            dense
          >
            <v-icon left>
              mdi-text-box-multiple
            </v-icon>
            <v-toolbar-title>{{ $t('form-settings.form-versions') }}</v-toolbar-title>
            <v-spacer />

            <!-- Add NEW version button -->
            <v-btn
              v-if="myRole() === 'manager'"
              :color="colorBtn"
              fab
              @click="dialogUploadFormVer = true"
            >
              <v-icon>mdi-text-box-plus</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text class="grey--text text--darken-1">
            <v-data-table
              :loading="formVersionsLoad"
              loading-text="Loading... Please wait"
              :headers="formversionheaders"
              :items="formVersions"
              item-key="id"
              :page.sync="fvpage"
              :items-per-page="fvPerPage"
              hide-default-footer
              @page-count="fvPageCount = $event"
            >
              <template v-slot:[`item.publishedAt`]="props">
                <timeago
                  :datetime="('publishedAt' in props.item) ? props.item.publishedAt : Date()"
                  :locale="$i18n.locale"
                  :auto-update="60"
                />
              </template>
              <!-- Action buttons -->
              <template v-slot:[`item.actions`]="{ item }">
                <!-- QR Code item -->
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <v-btn
                      v-if="item.name !== 'NA' && myRole() === 'manager'"
                      small
                      dark
                      icon
                      @click="getFormDefinition(item.version)"
                    >
                      <v-icon
                        color="primary"
                        v-on="on"
                      >
                        mdi-file-excel
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('tooltip.download-xlsform') }}</span>
                </v-tooltip>
              </template>

              <!-- No Collector button -->
              <!-- <template v-slot:no-data="">
                <v-btn to="/account/project">
                  {{ $t('form-settings.data-collector-add') }}
                </v-btn>
              </template> -->
            </v-data-table>
            <div class="text-center pt-2">
              <v-pagination
                v-model="fvpage"
                light
                :length="fvPageCount"
              />
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Form Settings Card -->
      <v-col
        md="5"
        lg="4"
        sm="12"
      >
        <v-card
          class="mt-2 mr-2"
          outlined
        >
          <v-toolbar
            flat
            :color="colorTlb"
            dark
            dense
          >
            <v-icon left>
              mdi-information-outline
            </v-icon>
            <v-toolbar-title>{{ $t('form-settings.settings') }}</v-toolbar-title>
            <v-spacer />

            <!-- Update form version button -->
            <v-btn
              v-if="myRole() === 'manager' && afsversState === 'outdated'"
              color="error"
              fab
              @click="dialogFSet = true"
            >
              <v-icon>mdi-autorenew</v-icon>
            </v-btn>
            <v-btn
              v-else
              color="success"
              fab
              @click="dialogFSet = true"
            >
              <v-icon>mdi-check-circle</v-icon>
            </v-btn>
          </v-toolbar>

          <!-- Form State Part -->
          <v-card-text>
            <v-select
              v-model="formstate"
              :items="items"
              :disabled="myRole() !== 'manager'"
              :hint="formstate.info"
              class="mt-4"
              item-text="state"
              item-value="code"
              :prepend-inner-icon="formstate.icon"
              outlined
              dense
              :label="$t('form-settings.form-state')"
              persistent-hint
              return-object
            />
            <v-card-actions>
              <v-spacer />
              <v-btn
                v-if="myRole() === 'manager'"
                color="error"
                class="mr-0"
                @click="openDialog()"
              >
                <v-icon left>
                  mdi-delete
                </v-icon>
                {{ $t('form-settings.delete-form') }}
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- DIALOGS -->
    <v-row justify="center">
      <!-- Add Data Collectors Dialog -->
      <v-dialog
        v-model="dialogDCol"
        persistent
        max-width="500px"
      >
        <v-card>
          <v-card-title>
            {{ $t('form-settings.data-collector-add') }}
          </v-card-title>
          <v-card-text>
            {{ $t('form-settings.data-coll-add-note') }}
          </v-card-text>
          <v-card-actions>
            <v-btn
              text
              color="grey darken-1"
              @click="dialogDCol = false"
            >
              {{ $t('common.close') }}
            </v-btn>
            <v-spacer />
            <v-btn
              text
              color="primary"
              to="/account/project"
            >
              {{ $t('form-settings.data-collector-add') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Show QR Code -->
      <v-dialog
        v-model="dialogQR"
        max-width="210"
      >
        <v-card>
          <!--
          <v-card-title>
            <v-spacer />
            <v-icon
              aria-label="Close"
              @click="dialogQR = false"
            >
              mdi-close
            </v-icon>
          </v-card-title>
          -->

          <v-card-text
            class="text-center ma-0 pa-1"
          >
            <qrcode
              ref="qr"
              :value="qrCode"
              :options="{
                width: 200,
                margin: 1,
              }"
            />
            <v-btn
              color="success"
              icon
              dark
              @click="saveQRCode()"
            >
              <v-icon>
                mdi-download
              </v-icon>
              {{ $t('common.download') }}
            </v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>

      <!-- Web Link Dialog -->
      <v-dialog
        v-model="dialogWebLink"
        persistent
        max-width="500px"
      >
        <v-card>
          <v-card-title>
            {{ $t('form-settings.gen-weblink') }}
          </v-card-title>
          <v-card-text>
            <validation-observer v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(addWebLink)">
                <validation-provider
                  v-slot="{ errors }"
                  name="Display Name of the link"
                  rules="required"
                >
                  <v-text-field
                    v-model="webDisplayName"
                    :error-messages="errors"
                    class="mt-5"
                    color="primary"
                    prepend-icon="mdi-tag"
                    :label="$t('table-header.display-name')"
                    outlined
                    dense
                    clearable
                  >
                    <template v-slot:append-outer>
                      <v-tooltip
                        bottom
                      >
                        <template v-slot:activator="{ on }">
                          <v-btn
                            color="warning"
                            icon
                            dark
                            small
                            v-on="on"
                            @click="shortUrl"
                          >
                            <v-icon>mdi-cog</v-icon>
                          </v-btn>
                        </template>
                        {{ $t('tooltip.shorturl') }}
                      </v-tooltip>
                    </template>
                  </v-text-field>
                </validation-provider>
                <validation-provider
                  v-if="webCustom"
                  v-slot="{ errors }"
                  name="Url Option"
                  :rules="{ required: true, min:5, max:30 }"
                >
                  <v-row
                    v-if="webCustom"
                    class="pl-10 pr-12 mt-1"
                  >
                    <div
                      class="text-right grey--text mt-2 pl-2"
                    >
                      https://is.gd/
                    </div>
                    <v-text-field
                      v-model="webUrlOption"
                      :error-messages="errors"
                      :label="$t('form-settings.link-shorturl')"
                      outlined
                      clearable
                      dense
                      required
                      counter="30"
                      @input="noSpace"
                    />
                  </v-row>
                </validation-provider>

                <validation-provider
                  v-slot="{ errors }"
                  name="Single submission checkbox"
                >
                  <v-checkbox
                    v-model="webSingle"
                    class="pl-8 mt-0"
                    dense
                    :error-messages="errors"
                    :label="$t('table-header.single-submission')"
                    type="checkbox"
                    :hint="$t('table-header.single-submission-hint')"
                    persistent-hint
                  />
                </validation-provider>

                <v-row class="pt-8">
                  <v-btn
                    color="grey darken-1"
                    text
                    @click="dialogWebLink = false"
                  >
                    {{ $t('common.cancel') }}
                  </v-btn>
                  <v-spacer />
                  <v-btn
                    color="primary"
                    text
                    type="submit"
                  >
                    {{ $t('common.save') }}
                  </v-btn>
                </v-row>
              </form>
            </validation-observer>
          </v-card-text>
        </v-card>
      </v-dialog>

      <!-- Delete Public link Dialog -->
      <v-dialog
        v-model="dialogDeleteLink"
        persistent
        max-width="400"
      >
        <v-card>
          <v-card-title class="headline font-weight-regular primary white--text py-2">
            {{ $t('common.heads-up') }}
          </v-card-title>
          <validation-observer v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(deleteWebLink)">
              <v-card-text class="pb-1">
                {{ $t('form-settings.will-del-link') }}
                <validation-provider
                  v-slot="{ errors }"
                  name="Agree checkbox"
                  rules="required"
                >
                  <v-checkbox
                    v-model="deletelinkagree"
                    :error-messages="errors"
                    :label="$t('form-settings.sure')"
                    value="1"
                    type="checkbox"
                    required
                  />
                </validation-provider>
              </v-card-text>
              <v-card-actions class="pt-0">
                <v-spacer />
                <v-btn
                  color="green darken-1"
                  text
                  @click="dialogDeleteLink = false"
                >
                  {{ $t('common.cancel') }}
                </v-btn>
                <v-btn
                  text
                  type="submit"
                >
                  {{ $t('common.delete') }}
                </v-btn>
              </v-card-actions>
            </form>
          </validation-observer>
        </v-card>
      </v-dialog>

      <!-- Join Table Dialog -->
      <v-dialog
        v-model="dialogJoin"
        persistent
        max-width="500px"
      >
        <v-card>
          <v-card-title>
            {{ $t('mpref.select-var') }}
          </v-card-title>
          <v-card-text>
            <validation-observer v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(joinTables)">
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('common.table')"
                  rules="required"
                >
                  <v-select
                    v-model="tbla"
                    :items="dataSet"
                    :error-messages="errors"
                    item-text="label[0]"
                    item-value="table[0]"
                    prepend-icon="mdi-table"
                    :label="$t('mpref.select-tbl')"
                    outlined
                    dense
                    return-object
                    validate-on-blur
                    @change="dataSetSelected"
                  />
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('common.table')"
                  rules="required"
                >
                  <v-select
                    v-model="tblb"
                    :items="childTable"
                    :error-messages="errors"
                    item-text="label[0]"
                    item-value="table[0]"
                    prepend-icon="mdi-table-column-plus-before"
                    :label="$t('mpref.select-tbl')"
                    outlined
                    dense
                    return-object
                    validate-on-blur
                  />
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  name="Title of Table"
                  rules="required"
                >
                  <v-text-field
                    v-model="tbltitle"
                    :error-messages="errors"
                    class="mt-5"
                    color="primary"
                    prepend-icon="mdi-pencil"
                    label="Title of Table"
                    outlined
                    dense
                    clearable
                  />
                </validation-provider>

                <v-row class="pt-4">
                  <v-btn
                    color="grey darken-1"
                    text
                    @click="dialogJoin = false"
                  >
                    {{ $t('common.cancel') }}
                  </v-btn>
                  <v-spacer />
                  <v-btn
                    color="primary"
                    text
                    type="submit"
                  >
                    {{ $t('common.save') }}
                  </v-btn>
                </v-row>
              </form>
            </validation-observer>
          </v-card-text>
        </v-card>
      </v-dialog>

      <!-- Upload new Form Versions Dialog -->
      <v-dialog
        v-model="dialogUploadFormVer"
        persistent
        max-width="500"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ $t('project-list.upload-xlsform') }}</span>
          </v-card-title>
          <v-card-text>
            <validation-observer v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(uploadXLSForm)">
                <v-row>
                  <v-col
                    cols="12"
                    class="pt-0"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="XLSForm"
                      rules="required"
                    >
                      <v-file-input
                        v-model="xlsform"
                        class="mx-4"
                        :error-messages="errors"
                        :label="$t('project-list.select-xlsform')"
                        dense
                        outlined
                        full-width
                        accept=".xlsx"
                        @change="xlsFormSelected()"
                      />
                    </validation-provider>
                  </v-col>
                  <v-col
                    cols="12"
                    class="pt-0"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Language"
                      rules="required"
                    >
                      <v-autocomplete
                        v-model="defLang"
                        class="mx-4"
                        :items="formLang"
                        :error-messages="errors"
                        :loading="xlsfuploading"
                        item-text="label"
                        item-value="code"
                        :label="$t('project-list.def-lang')"
                        prepend-icon="mdi-web"
                        dense
                        outlined
                        return-object
                      />
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <small class="mx-6 grey--text">
                    {{ $t('project-list.xlsform-note') }}
                  </small>
                </v-row>
                <v-row>
                  <v-btn
                    color="warning"
                    text
                    @click="getxlsformtemplate()"
                  >
                    {{ $t('project-list.xlsform-sample') }}
                  </v-btn>
                  <v-spacer />
                  <v-btn
                    text
                    @click="dialogUploadFormVer = false"
                  >
                    {{ $t('common.cancel') }}
                  </v-btn>
                  <v-btn
                    color="success"
                    text
                    type="submit"
                    :loading="loading"
                    :disabled="uploadDisabled"
                  >
                    {{ $t('common.upload') }}
                  </v-btn>
                </v-row>
              </form>
            </validation-observer>
          </v-card-text>
        </v-card>
      </v-dialog>

      <!-- Update Form Settings Dialog -->
      <v-dialog
        v-model="dialogFSet"
        persistent
        max-width="500px"
      >
        <v-card
          v-if="afsversState !== 'wait'"
          :loading="formUpgrading"
        >
          <v-card-title>
            {{ $t('form-settings.settings') }}
          </v-card-title>
          <v-card-text v-if="myRole() === 'manager' && afsversState === 'outdated'">
            Latest version of the application in the server is v{{ this.$store.state.main.afsvers.appversion.vstring[0] }} <br>
            Current settings of this form is v{{ this.$store.state.main.afsvers.fromversion.vstring[0] }} <br>
            Please, upgrade to use all latest features of UDACAPI
          </v-card-text>
          <v-card-text v-else>
            The Survey Form is using the all the latest features of the UDACAPI! (v{{ this.$store.state.main.afsvers.fromversion.vstring[0] }})
          </v-card-text>
          <v-card-actions>
            <v-btn
              v-if="myRole() === 'manager' && afsversState === 'outdated' && !formUpgrading"
              text
              color="grey darken-1"
              @click="dialogFSet = false"
            >
              {{ $t('common.cancel') }}
            </v-btn>
            <v-btn
              v-if="myRole() === 'manager' && afsversState === 'outdated' && formUpgrading"
              text
              color="grey darken-1"
              @click="dialogFSet = false"
            >
              {{ $t('common.close') }}
            </v-btn>
            <v-spacer />
            <v-btn
              v-if="myRole() === 'manager' && afsversState === 'outdated'"
              text
              color="primary"
              :disabled="formUpgrading"
              @click="upgradeActiveForm()"
            >
              {{ $t('common.upgrade') }}
            </v-btn>
            <v-btn
              v-else
              text
              color="grey darken-1"
              @click="dialogFSet = false"
            >
              {{ $t('common.close') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Delete Form Dialog -->
      <v-dialog
        v-model="dialogDeleteForm"
        persistent
        max-width="400"
      >
        <v-card>
          <v-card-title class="headline font-weight-regular primary white--text py-2">
            {{ $t('common.heads-up') }}
          </v-card-title>
          <validation-observer v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(deleteform)">
              <v-card-text class="pb-1">
                {{ $t('form-settings.will-del-form') }} <b>{{ af.title }}</b> (<b>{{ af.submissions }}</b> {{ $t('common.submissions') }}).
                {{ $t('form-settings.action-note') }}
                <validation-provider
                  v-slot="{ errors }"
                  name="Agree checkbox"
                  rules="required"
                >
                  <v-checkbox
                    v-model="agreecheckbox"
                    :error-messages="errors"
                    :label="$t('form-settings.sure')"
                    value="1"
                    type="checkbox"
                    required
                  />
                </validation-provider>
              </v-card-text>
              <v-card-actions class="pt-0">
                <v-spacer />
                <v-btn
                  color="green darken-1"
                  text
                  @click="dialogDeleteForm = false"
                >
                  {{ $t('common.cancel') }}
                </v-btn>
                <v-btn
                  text
                  type="submit"
                >
                  {{ $t('common.delete') }}
                </v-btn>
              </v-card-actions>
            </form>
          </validation-observer>
        </v-card>
      </v-dialog>
    </v-row>

    <!-- New SnackkBar -->
    <v-snackbar
      v-model="snackBar.visible"
      auto-height
      :color="snackBar.color"
      :multi-line="snackBar.mode === 'multi-line'"
      :timeout="snackBar.timeout"
      :top="snackBar.position === 'top'"
    >
      <v-row>
        <v-icon
          class="pr-3"
          dark
          large
        >
          {{ snackBar.icon }}
        </v-icon>
        <v-col>
          <div>
            <strong>{{ snackBar.title }}</strong>
          </div>
          <div>{{ snackBar.text }}</div>
        </v-col>
      </v-row>
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          icon
          @click="snackBar.visible = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import VueTimeago from 'vue-timeago'
  import Vue from 'vue'
  import VueQrcode from '@chenfengyuan/vue-qrcode'
  import userAccess from '@/mixins/user-access'
  import dashboard from '../../../mixins/dashboard'
  import utilities from '@/mixins/utilities'

  // Mixins
  import organization from '@/mixins/organization'
  import odkForms from '@/mixins/odkforms'

  Vue.use(VueTimeago, {
    name: 'Timeago', // Component name, `Timeago` by default
    locale: 'en-US', // Default locale
    locales: {
      pt: require('date-fns/locale/pt'),
      ru: require('date-fns/locale/ru'),
      tr: require('date-fns/locale/tr'),
    },
  })

  // import axios from 'axios'
  Vue.component(VueQrcode.name, VueQrcode)

  export default {
    name: 'FormSettings',

    components: {},

    mixins: [
      organization,
      odkForms,
      userAccess,
      dashboard,
      utilities,
    ],

    data: () => ({
      helptoolbar: true,

      ix: '',
      af: {},
      orgid: '',
      prjid: '',
      formid: '',
      formSettingVersion: '100',
      // json uniq id of selected form
      formjsonid: '',
      formDescription: '',

      // Form delete dialog
      dialogDeleteForm: false,
      agreecheckbox: false,
      select: {},

      itemsPerPage: 5,
      fvPerPage: 2,

      dcpage: 1,
      dcPageCount: 0,
      appUsers: [],
      appUserLoad: false,
      selected: [],
      search: undefined,

      formUpgrading: false,

      // Web access links
      plpage: 1,
      plPageCount: 0,
      webLinkLoad: false,
      webSingle: false,
      webCustom: false,
      webUrlOption: '',
      webDisplayName: '',
      webLinks: [],
      dialogDeleteLink: false,
      deletelinkagree: false,
      deletelinkid: '',

      // form Versions
      fvpage: 1,
      fvPageCount: 0,
      formVersionsLoad: false,
      formVersions: [],

      tbla: '',
      tblb: '',
      tbltitle: '',
      childTable: [],

      dialogDCol: false,
      dialogQR: false,
      dialogWebLink: false,
      dialogJoin: false,
      dialogUploadFormVer: false,
      dialogFSet: false,

      qrCodeDisplayName: '',
      qrCode: '',
      // headers: [
      //   {
      //     text: 'Dessert (100g serving)',
      //     align: 'start',
      //     sortable: false,
      //     value: 'name',
      //   },
      //   { text: 'Section', value: 'type' },
      //   { text: 'Select', value: 'glutenfree' },
      // ],

      loading_fsettings: false,
      colorBtn: 'secondary',
      colorTlb: 'orange darken-2',

      sMode: '600000',

      syncMode: [
        { label: 'mpref.fs-sync-rt', value: '60000', color: 'red', selected: false },
        { label: 'mpref.fs-sync-freq', value: '300000', color: 'orange', selected: false },
        { label: 'mpref.fs-sync-moder', value: '600000', color: 'green', selected: true },
        { label: 'mpref.fs-sync-manual', value: '1200000', color: 'indigo', selected: false },
      ],

      xlsfuploading: false,
      xlsform: null,
      formLang: [],
      defLang: [],
      loading: false,
      uploadDisabled: false,

      // notification about sync
      snackBar: {
        color: 'success',
        icon: 'mdi-information',
        mode: 'multi-line',
        position: 'top',
        timeout: 7500,
        title: 'Success',
        text: 'That worked, hoorah.',
        visible: false,
      },
    }),

    computed: {
      // Keep dBlock info open or closed
      dBInfo: {
        get () {
          return this.$store.state.main.dsb.fsettings.dSettingsInfo
        },
        set (value) {
          this.$store.commit('main/setfSetting', { el: 'dSettingsInfo', val: value })
          // console.log(this.$store.state.main.dsb.fsettings)
          this.dBlockSave('fsettings', this.$store.state.main.dsb.fsettings)
        },
      },

      formstate: {
        get () {
          const aState = this.items.filter(a => a.code === this.af.odksettings.state)
          return aState[0]
        },
        set (value) {
          // console.log(value)
          this.af.odksettings.state = value.code
          this.$store.commit('bend/updateOdkSettings', {
            ix: this.ix,
            settings: this.af.odksettings,
          })
          // Call setfstate() to change form state in Central
          this.setfstate({ action: 'set', fstate: value.code })

          // Call patchOdkForm() from odkforms.js to patch form bend
          const patchBody = {
            data: {
              type: 'node--form',
              id: this.af.id,
              attributes: {
                field_form_settings: JSON.stringify(this.af.odksettings),
              },
            },
          }
          this.patchOdkForm({ orgid: this.orgid, formjsonid: this.af.id, patchBody: patchBody })
        },
      },

      items () {
        return [
          { state: this.$t('form-settings.open'), code: 'open', icon: 'mdi-cloud-check', info: this.$t('form-settings.open-note') },
          { state: this.$t('form-settings.closing'), code: 'closing', icon: 'mdi-cloud-alert', info: this.$t('form-settings.closing-note') },
          { state: this.$t('form-settings.closed'), code: 'closed', icon: 'mdi-cloud-lock', info: this.$t('form-settings.closed-note') },
        ]
      },

      appuheaders () {
        return [
          { text: this.$t('table-header.nickname'), value: 'displayName' },
          { text: this.$t('table-header.actions'), value: 'actions', sortable: false },
        ]
      },

      weblinkheaders () {
        return [
          { text: this.$t('table-header.name'), value: 'name' },
          { text: this.$t('table-header.single-submission'), value: 'single' },
          { text: this.$t('table-header.link'), value: 'link' },
          { text: this.$t('table-header.actions'), value: 'actions', sortable: false },
        ]
      },

      formversionheaders () {
        return [
          { text: this.$t('table-header.name'), value: 'name' },
          { text: this.$t('project-list.instance-name'), value: 'xmlFormId' },
          { text: this.$t('common.version'), value: 'version' },
          { text: this.$t('table-header.published'), value: 'publishedAt' },
          { text: this.$t('table-header.actions'), value: 'actions', sortable: false },
        ]
      },

      dataSet () {
        return this.$store.state.ocpu.tableListRules
      },

      isWebLink () {
        return (this.webLinks[0])
      },

      afsversState () {
        let st
        if (Object.keys(this.$store.state.main.afsvers).length === 0) {
          st = 'wait'
        } else {
          st = this.$store.state.main.afsvers.state[0]
        }
        return (st)
      },
    },

    created () {
      const main = this.$store.state.main
      this.ix = main.ix
      // console.log(main)
      this.af = main.af
      this.formid = main.af.odksettings.id
      this.formjsonid = main.af.id
      this.formDescription = main.af.body
      this.questions = main.af.odkquestions
      this.languages = main.af.odklangs
      this.orgid = main.orgid
      this.ap = main.ap
      this.prjid = main.ap.prjid

      this.$store.commit('odkform/getOdkForm', {
        models: this.af.odkmodels,
        questions: this.af.odkquestions,
        langs: this.af.odklangs,
        settings: this.af.odksettings,
      })

      const payload = {
        orgid: this.$store.state.bend.organization.orgid,
        prjid: this.prjid,
        formid: main.afid,
      }

      // TODO save tableListRules into each form (same in MonitoringPrefence)
      this.$store.dispatch('ocpu/ocTableListRules', payload).then(
        response => {
          // console.log(response)
        },
      )
    },

    mounted () {
      this.getAppUser()
      this.getWebLink(false)
      this.getFormVersions()
      this.fSVersions()
      this.helptoolbar = this.$store.state.main.dsb.fsettings.dSettingsInfo
      const updatedQc = this.$store.state.main.qrCode.updated
      if (Object.keys(updatedQc).length === 0) {
        this.getQcDetails()
      }
    },

    methods: {
      translateMe (val) {
        return this.$t(val)
      },

      setfstate (prop) {
        const ocpuPack = this.$store.state.ocpu.ocpuPack
        const main = this.$store.state.main
        const data = {
          orgid: this.$store.state.bend.organization.orgid,
          prjid: main.ap.prjid,
          formid: main.af.odksettings.id,
          action: prop.action,
          fstate: prop.fstate,
        }
        // console.log(data)
        this.$httpOcpu
          .post(`/${ocpuPack}/R/ocformstate/json`, data, {
          }).then((res) => {
            // console.log(res.data)
          })
      },

      fSVersions (forse) {
        // console.log('response')
        const main = this.$store.state.main
        // if (Object.keys(this.$store.state.main.afsvers).length === 0 | forse) {
        const ocpuPack = this.$store.state.ocpu.ocpuPack
        return new Promise((resolve, reject) => {
          this.$httpOcpu
            .post(`/${ocpuPack}/R/getversions/json`, {
              orgid: this.$store.state.bend.organization.orgid,
              prjid: this.prjid,
              formid: main.afid,
            })
            .then(response => {
              // console.log(response)
              // console.log(response.data)
              if (response.status === 201) {
                this.$store.commit('main/setfSVersion', response.data)
                resolve(response)
              }
            })
            .catch(error => {
              // console.log(error)
              reject(error)
            })
        })
        // }
        // return (this.$store.state.main.afsvers)
      },

      upgradeActiveForm () {
        this.formUpgrading = true
        const ocpuPack = this.$store.state.ocpu.ocpuPack
        return new Promise((resolve, reject) => {
          this.$httpOcpu
            .post(`/${ocpuPack}/R/restorestables/json`, {
              orgid: this.orgid,
              prjid: this.prjid,
              formid: this.$store.state.main.afid,
            })
            .then(response => {
              this.formUpgrading = false
              // console.log(response)
              if (response.status === 201) {
                this.dialogFSet = false
                resolve(response)
              }
              this.fSVersions(true)
              this.formUpgrading = false
            })
            .catch(error => {
              // console.log(error)
              reject(error)
            })
        })
      },

      async deleteform () {
        // Run code from mixin odkForms to delete form's folder completely from ocpu and from bend
        await this.deleteOdkForm()
        this.dialogDeleteForm = false
        this.goBack()
        this.$router.push({ name: 'Active Forms' })
      },

      openDialog () {
        this.agreecheckbox = false
        this.dialogDeleteForm = true
      },

      dataSetSelected (selectedTable) {
        const bcname = this.formid === selectedTable.table[0] ? selectedTable.table[0] : `${this.formid}-${selectedTable.table[0]}`
        this.childTable = this.dataSet.filter(tbl => tbl.parent[0] === bcname)
      },

      // Run ocjoin() to join tables in OCPU
      joinTables () {
        const details = {
          title: this.tbltitle,
          tbla: this.tbla.table[0],
          tblb: this.tblb.table[0],
        }
        this.tbltitle = ''
        this.tbla.table[0] = ''
        this.tblb.table[0] = ''
        // console.log(details)
        const ocpuPack = this.$store.state.ocpu.ocpuPack
        return new Promise((resolve, reject) => {
          this.$httpOcpu
            .post(`/${ocpuPack}/R/ocjoin/json`, {
              details: JSON.stringify(details),
              orgid: this.orgid,
              prjid: this.prjid,
              formid: this.formid,
            })
            .then(response => {
              // console.log(response)
              if (response.status === 201) {
                const payload = {
                  orgid: this.$store.state.bend.organization.orgid,
                  prjid: this.prjid,
                  formid: this.formid,
                }

                // TODO save tableListRules into each form (same in MonitoringPrefence)
                this.$store.dispatch('ocpu/ocTableListRules', payload).then(
                  response => {
                    // console.log(response)
                  },
                )
                this.dialogJoin = false
                resolve(response)
              }
            })
            .catch(error => {
              // console.log(error)
              reject(error)
            })
        })
      },

      // Run ocjoin() to join tables in OCPU
      joinTableDelete (jtbl) {
        //  console.log(jtbl)
        const ocpuPack = this.$store.state.ocpu.ocpuPack
        return new Promise((resolve, reject) => {
          this.$httpOcpu
            .post(`/${ocpuPack}/R/ocjoindelete/json`, {
              orgid: this.orgid,
              prjid: this.prjid,
              formid: this.formid,
              jtable: jtbl,
            })
            .then(response => {
              // console.log(response)
              if (response.status === 201) {
                const payload = {
                  orgid: this.$store.state.bend.organization.orgid,
                  prjid: this.prjid,
                  formid: this.formid,
                }

                // TODO save tableListRules into each form (same in MonitoringPrefence)
                this.$store.dispatch('ocpu/ocTableListRules', payload).then(
                  response => {
                    // console.log(response)
                  },
                )
                // this.getMtoolsList()
                resolve(response)
              }
            })
            .catch(error => {
              // console.log(error)
              reject(error)
            })
        })
      },
      // return table type core or join
      coretable (tbl) {
        // console.log(tbl)
        const iscore = tbl === true ? 'form-settings.main' : 'form-settings.join'

        return iscore
      },

      // clear Display and Short url and activate dialog
      webLinkDialog () {
        this.webCustom = false
        this.webUrlOption = ''
        this.webDisplayName = ''
        this.dialogWebLink = true
      },

      // Get Web link
      async getWebLink (prop) {
        this.webLinkLoad = true
        const main = this.$store.state.main
        const payload = {}
        payload.orgid = main.orgid
        payload.prjid = main.ap.prjid
        payload.formid = main.afid
        payload.name = ''
        payload.id = ''
        payload.action = 'get'
        if (prop) payload.urloption = this.webUrlOption
        this.$store.dispatch('ocpu/webLink', payload).then(
          response => {
            // console.log(response)
            // console.log(response.data)
            this.webLinks = response.data
            this.webLinkLoad = false
          },
          error => {
            this.webLinkLoad = false
            return (error)
            // console.log('Got nothing from server via ' + error)
          },
        )
      },

      // Add Web link
      async addWebLink () {
        const main = this.$store.state.main
        const payload = {}
        payload.orgid = main.orgid
        payload.prjid = main.ap.prjid
        payload.formid = main.afid
        payload.name = this.webDisplayName
        payload.once = this.webSingle
        payload.id = ''
        payload.action = 'post'
        // console.log(payload)
        this.$store.dispatch('ocpu/webLink', payload).then(
          response => {
            // console.log(response.data)
            this.getWebLink(true)
          },
          error => {
            return (error)
            // console.log('Got nothing from server via ' + error)
          },
        )
        this.dialogWebLink = false
      },

      openDialogDeleteLink (item) {
        // console.log(item)
        this.deletelinkid = item
        this.deletelinkagree = false
        this.dialogDeleteLink = true
      },

      // Delete Web link
      async deleteWebLink () {
        const main = this.$store.state.main
        const payload = {}
        payload.orgid = main.orgid
        payload.prjid = main.ap.prjid
        payload.formid = main.afid
        payload.name = ''
        payload.once = ''
        payload.id = this.deletelinkid
        payload.action = 'delete'
        // console.log(payload)
        this.$store.dispatch('ocpu/webLink', payload).then(
          response => {
            // console.log(response)
            this.getWebLink(false)
            this.dialogDeleteLink = false
            this.deletelinkid = ''
            // this.appUsers = response.data
          },
          error => {
            return (error)
            // console.log('Got nothing from server via ' + error)
          },
        )
      },

      // Application User (e.g. Data Collectors) actions
      async getAppUser () {
        this.appUserLoad = true
        const main = this.$store.state.main
        const payload = {}
        payload.orgid = main.orgid
        payload.prjid = main.ap.prjid
        payload.action = 'get'
        this.$store.dispatch('ocpu/appUser', payload).then(
          response => {
            // console.log(response)
            this.appUsers = response.data
            // const p = this.$store.state.bend.organization.projects[this.ix.pindex]
            const props = {
              orgid: main.orgid,
              prjid: main.ap.prjid,
              formid: main.af.odksettings.id,
              roleid: 'app-user',
            }
            // console.log(props)
            this.$store.dispatch('ocpu/appUserActive', props).then(
              response => {
                // console.log(response)
                // Filter form's app user and update this.selected to indicate them on data table for data collectors
                this.selected = this.appUsers.filter((user) => response.data.find(({ id }) => user.id[0] === id[0]))
                this.appUserLoad = false
              },
            )
          },
          error => {
            return (error)
            // console.log('Got nothing from server via ' + error)
          },
        )
      },

      updateAppUsers (item) {
        const ocpuPack = this.$store.state.ocpu.ocpuPack
        // console.log(item)
        const p = this.$store.state.bend.organization.projects[this.ix.pindex]
        return new Promise((resolve, reject) => {
          this.$httpOcpu
            .post(`/${ocpuPack}/R/ocformappuser/json`, {
              orgid: this.$store.state.bend.organization.orgid,
              prjid: p.prjid,
              formid: p.forms[this.ix.findex].odksettings.id,
              roleid: 'app-user',
              actorid: item.item.id[0],
              assign: item.value,
            })
            .then(response => {
              // console.log(response)
              // if (response.status === 201) {
              //   resolve(response)
              // }
            })
            .catch(error => {
              // console.log(error)
              reject(error)
            })
        })
      },

      // Draft form methods
      xlsFormSelected () {
        const ocpuPack = this.$store.state.ocpu.ocpuPack
        const main = this.$store.state.main
        // console.log(main.orgid)
        if (this.xlsform !== undefined) {
          if (Object.keys(this.xlsform).length === 0) {
            // const fpath = `${this.orgid}/${this.prjid}/${this.formid}`
            // console.log(fpath)

            this.xlsfuploading = true
            this.formLang = []
            this.defLang = []
            const formData = new FormData()
            formData.append('orgid', JSON.stringify(main.orgid))
            formData.append('prjid', JSON.stringify(main.ap.prjid))
            formData.append('formid', JSON.stringify(main.afid))
            formData.append('xlsform', this.xlsform)
            // console.log(formData)
            this.$httpOcpu
              .post(`/${ocpuPack}/R/ocxlsform/json`, formData).then((res) => {
                this.formLang = res.data
                this.defLang = res.data[0]
                this.xlsfuploading = false
                // console.log(res)
              })
          }
        }
      },

      getxlsformtemplate () {
        const ocpuPack = this.$store.state.ocpu.ocpuPack
        const filename = { filename: 'XLSFormTemplate.xlsx' }
        // console.log(filename)
        this.$httpOcpu.post(`/${ocpuPack}/R/getufile/json`, filename)
          .then(response => {
            // console.log(response)
            const link = `${response.headers.location}/files/${response.data[0]}`
            const fileName = response.data[0]
            // console.log(link)
            // console.log(fileName)
            axios({
              url: link,
              method: 'GET',
              responseType: 'blob',
            }).then(response => {
              const fileURL = window.URL.createObjectURL(new Blob([response.data]))
              const fileLink = document.createElement('a')
              fileLink.href = fileURL
              fileLink.setAttribute('download', fileName)
              document.body.appendChild(fileLink)
              fileLink.click()
              window.URL.revokeObjectURL(fileURL)
            })
          })
      },

      uploadXLSForm () {
        const ocpuPack = this.$store.state.ocpu.ocpuPack
        this.loading = true
        const main = this.$store.state.main
        const ix = this.getIx({ project: main.ap, form: main.af })
        const fver = this.af.odksettings.version + 1
        this.af.odksettings.version = fver
        this.af.odksettings.phase = 'draft'
        this.$store.commit('bend/updateOdkSettings', {
          ix: this.ix,
          settings: this.af.odksettings,
        })
        const prop = {
          fsettings: JSON.stringify(this.af.odksettings),
          orgid: main.orgid,
          prjid: main.ap.prjid,
          formid: main.afid,
          deflang: this.defLang.code,
          newdraft: true,
        }
        // console.log(prop)
        return new Promise((resolve, reject) => {
          this.$httpOcpu
            .post(`/${ocpuPack}/R/ocuploadxlsf/json`, prop, {
            }).then((res) => {
              // Run if response is successfull
              // console.log(res)
              if (res.data.status.category[0] === 'Success') {
                // if response is successfull
                this.$store.dispatch('bend/odkFormPhase', {
                  ix: ix,
                  formjsonid: main.af.id,
                  phase: 'deployed',
                  attachment: res.data.attachment,
                  enketoid: res.data.data.enketoId,
                }).then(() => {
                  // console.log('uploadXLSForm and sending to getFormVersions()')
                  this.$router.push({ name: 'Project List' })
                })
                this.loading = false
                this.dialogUploadFormVer = false
              } else if (res.data.status.category[0] === 'Client error') {
                this.loading = false
                this.snackBar = {
                  color: 'error',
                  icon: 'mdi-alert-circle',
                  mode: 'multi-line',
                  position: 'top',
                  timeout: 12000,
                  title: `${this.$t('common.error')}`,
                  text: `${res.data.status.message[0]}. ${res.data.data.message[0]} ${res.data.data.details.error[0]}`,
                  visible: true,
                }
                this.uploadDisabled = true
              } else {
                this.loading = false
                this.snackBar = {
                  color: 'error',
                  icon: 'mdi-alert-circle',
                  mode: 'multi-line',
                  position: 'top',
                  timeout: 12000,
                  title: `${this.$t('common.error')} ${res.data.status.category[0]}`,
                  text: `${res.data.data.message[0]} ${res.data.data.details.error[0]}`,
                  visible: true,
                }
                this.uploadDisabled = true
              }
            })
            .catch(error => {
              // console.log(error)
              return reject(error.message)
            })
        })
      },

      // Get Form versions
      async getFormVersions () {
        this.formVersionsLoad = true
        const main = this.$store.state.main
        const payload = {}
        payload.orgid = main.orgid
        payload.prjid = main.ap.prjid
        payload.formid = main.afid
        this.$store.dispatch('ocpu/formVersions', payload).then(
          response => {
            // console.log(response)
            this.formVersions = response.data
            this.formVersionsLoad = false
          },
          error => {
            this.formVersionsLoad = false
            // console.log('Got nothing from server via ' + error)
            return (error)
          },
        )
      },

      getFormDefinition (version) {
        // console.log(version)
        const ocpuPack = this.$store.state.ocpu.ocpuPack
        const main = this.$store.state.main
        const payload = {}
        payload.orgid = main.orgid
        payload.prjid = main.ap.prjid
        payload.formid = main.afid
        payload.version = version[0]
        // console.log(filename)
        this.$httpOcpu.post(`/${ocpuPack}/R/getformversion/json`, payload)
          .then(response => {
            // console.log(response)
            const link = `${response.headers.location}/files/${response.data[0]}`
            const fileName = response.data[0]
            // console.log(link)
            // console.log(fileName)
            axios({
              url: link,
              method: 'GET',
              responseType: 'blob',
            }).then(response => {
              const fileURL = window.URL.createObjectURL(new Blob([response.data]))
              const fileLink = document.createElement('a')
              fileLink.href = fileURL
              fileLink.setAttribute('download', fileName)
              document.body.appendChild(fileLink)
              fileLink.click()
              window.URL.revokeObjectURL(fileURL)
            })
          })
        // this.snackBar = {
        //   color: 'info',
        //   icon: 'mdi-information',
        //   mode: 'multi-line',
        //   position: 'top',
        //   timeout: 4500,
        //   // title: `${this.$t('common.error')}`,
        //   text: 'Possibility to download XLSForms is coming soon',
        //   visible: true,
        // }
        // this.uploadDisabled = true
        // console.log(version)
      },

      getQcDetails () {
        const ocpuPack = this.$store.state.ocpu.ocpuPack
        return new Promise((resolve, reject) => {
          this.$httpOcpu
            .post(`/${ocpuPack}/R/ocgetqcode/json`, {
              orgid: this.orgid,
              prjid: this.prjid,
            })
            .then(response => {
              // console.log(response)
              // console.log(response.data)
              if (response.status === 201) {
                this.$store.commit('main/setQcUpdated', JSON.parse(response.data))
                // this.updateQcDetails()
                resolve(response)
              }
            })
            .catch(error => {
              // console.log(error)
              reject(error)
            })
        })
      },

      showQRCode (item) {
        // console.log(item)
        this.qrCodeDisplayName = item.displayName[0]
        const updatedQc = this.$store.state.main.qrCode.updated
        const server = this.$store.state.main.ap.odk_server
        // console.log(server)
        // Add server_url value of section general
        updatedQc.general.server_url = `${server}/v1/key/${item.token[0]}/projects/${item.projectId[0]}`
        this.dialogQR = true
        // https://docs.getodk.org/collect-import-export/#list-of-keys-for-all-settings

        var pako = require('pako')
        // encode to Uint8Array
        var Uint8Array = new TextEncoder('utf-8').encode(JSON.stringify(updatedQc))
        // compress
        var bS = pako.deflate(Uint8Array, { to: 'string' })
        // encode base 64
        var bs64 = btoa(bS)
        this.qrCode = bs64

        // // decoding working settings
        // // decode base64
        // var wbin = atob(this.qrCode)
        // console.log(wbin)
        // // decompress
        // var foo = pako.inflate(wbin)
        // console.log(foo)
        // // decode from Uint8Array
        // var string = new TextDecoder('utf-8').decode(foo)
        // console.log(string)
      },

      saveQRCode () {
        // console.log(this.af.odksettings.id)
        const img = this.$refs.qr.$el.toDataURL()
        var link = document.createElement('a')
        link.download = `QR_Code-${this.af.odksettings.id}-${this.qrCodeDisplayName}.png`
        link.href = img
        // console.log(link)
        document.body.appendChild(link)
        link.click()
        this.dialogQR = false
      },

      // Manual name the form
      shortUrl () {
        // console.log(this.webUrlOption)
        this.webCustom = !this.webCustom
        if (this.webCustom) {
          this.formEditIcon = 'mdi-restore'
        } else {
          this.webUrlOption = ''
        }
      },

      noSpace () {
        this.webUrlOption = this.webUrlOption.replace(/\W/g, '_').toLowerCase()
      },

    },
  }
</script>

<style>
    /* Comments */
</style>
